body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print
{    
  .no-print, .no-print *
  {
    display: none !important;
  }
}
li {
  list-style: none;
  border-top: 1px solid #ddd;
  display: grid;
  grid-template-columns: 30% 25% 25% auto;
  grid-gap: 20px;
}

li:hover {
  background: rgba(0, 0, 0, 15%) !important;
}

li:first-child {
  border-top: none;
}

li:nth-child(even) {
  background: rgba(0, 0, 0, 10%);
}

li div {
  display: inline-block;
  padding: 10px;
}

.price {
  text-align: right;
}

.actions {
  text-align: right;
}

.edit-button {
  padding: 5px 7px 5px 10px !important;
}

.edit-button:hover {
  background: orange !important;
  color: white;
}

.delete-button:hover {
  background: darkred;
  color: white;
}
.modal-dark {
  background: rgba(0, 0, 0, 70%);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

.modal-content {
  position: fixed;
  left: 10%;
  top: 10%;
  background: white;
  border: none;
  border-radius: 5px;
  padding: 20px;
}

.close-modal {
  float: right;
}

.modal-content form {
  margin-top: 20px;
}

.input-group {
  display: inline-block;
  padding: 0 15px 10px 0;
}

.input-group label {
  display: block;
  width: 10em;
}

form {
  display: grid;
  grid-template-columns: 20em 10em auto;
  grid-column-gap: 20px;
}

form input {
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}

.input-group input[type="text"] {
  width: 100%;
}

.input-group input[type="number"] {
  width: 100%;
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}

input[type="submit"] {
  background: green;
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 1em;
  font-size: 1em;
}

input[type="submit"]:hover {
  background: darkgreen;
}

input[type="submit"]:focus {
  background: gray;
}
#add-product {
  font-size: 5vw;
  background-image: -webkit-radial-gradient(white 60%, #ddd);
  background-image: radial-gradient(white 60%, #ddd);
  border: 1px solid #ddd;
  border-radius: 50%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 10%);
  position: fixed;
  left: 5px;
  top: 5px;
  width: 10vw;
  height: 10vw;
}

#add-product:hover {
  background-image: -webkit-radial-gradient(white 55%, #ddd);
  background-image: radial-gradient(white 55%, #ddd);
}

#add-product:focus {
  background-image: -webkit-radial-gradient(#eee 60%, #ccc);
  background-image: radial-gradient(#eee 60%, #ccc);
}

h2 {
  color: #ddd;
}

ul {
  margin: 0 10%;
  padding: 1em;
}
header {
  border-bottom: 1px solid #ddd;
}

h1 {
  text-align: center;
  font-size: 3em;
}

button {
  background: none;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}

button:hover {
  background: rgba(0, 0, 0, 10%);
}

button:focus {
  background: rgba(0, 0, 0, 25%);
}
