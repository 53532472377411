.modal-dark {
  background: rgba(0, 0, 0, 70%);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}

.modal-content {
  position: fixed;
  left: 10%;
  top: 10%;
  background: white;
  border: none;
  border-radius: 5px;
  padding: 20px;
}

.close-modal {
  float: right;
}

.modal-content form {
  margin-top: 20px;
}

.input-group {
  display: inline-block;
  padding: 0 15px 10px 0;
}

.input-group label {
  display: block;
  width: 10em;
}

form {
  display: grid;
  grid-template-columns: 20em 10em auto;
  grid-column-gap: 20px;
}

form input {
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}

.input-group input[type="text"] {
  width: 100%;
}

.input-group input[type="number"] {
  width: 100%;
  appearance: textfield;
}

input[type="submit"] {
  background: green;
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 1em;
  font-size: 1em;
}

input[type="submit"]:hover {
  background: darkgreen;
}

input[type="submit"]:focus {
  background: gray;
}