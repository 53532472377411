header {
  border-bottom: 1px solid #ddd;
}

h1 {
  text-align: center;
  font-size: 3em;
}

button {
  background: none;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}

button:hover {
  background: rgba(0, 0, 0, 10%);
}

button:focus {
  background: rgba(0, 0, 0, 25%);
}