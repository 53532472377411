li {
  list-style: none;
  border-top: 1px solid #ddd;
  display: grid;
  grid-template-columns: 30% 25% 25% auto;
  grid-gap: 20px;
}

li:hover {
  background: rgba(0, 0, 0, 15%) !important;
}

li:first-child {
  border-top: none;
}

li:nth-child(even) {
  background: rgba(0, 0, 0, 10%);
}

li div {
  display: inline-block;
  padding: 10px;
}

.price {
  text-align: right;
}

.actions {
  text-align: right;
}

.edit-button {
  padding: 5px 7px 5px 10px !important;
}

.edit-button:hover {
  background: orange !important;
  color: white;
}

.delete-button:hover {
  background: darkred;
  color: white;
}