#add-product {
  font-size: 5vw;
  background-image: radial-gradient(white 60%, #ddd);
  border: 1px solid #ddd;
  border-radius: 50%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 10%);
  position: fixed;
  left: 5px;
  top: 5px;
  width: 10vw;
  height: 10vw;
}

#add-product:hover {
  background-image: radial-gradient(white 55%, #ddd);
}

#add-product:focus {
  background-image: radial-gradient(#eee 60%, #ccc);
}

h2 {
  color: #ddd;
}

ul {
  margin: 0 10%;
  padding: 1em;
}